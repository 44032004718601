import {} from "prop-types";
import styled from "styled-components";

import { color, setType } from "../../utils";

const Link = styled.a`
  ${setType("x")};
  color: ${color.black};
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  &:hover {
    color: ${color.brownM};
  }
`;

export default Link;
