import {} from "prop-types";
import styled from "styled-components";

import { color, setType } from "../../utils";

const PageSubtitle = styled.h2`
  ${setType("h")};
  color: ${color.black};
  font-weight: bold;
  text-transform: uppercase;
`;

PageSubtitle.propTypes = {};

PageSubtitle.defaultProps = {};

export default PageSubtitle;
