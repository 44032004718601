import {} from "prop-types";
import styled from "styled-components";

import { color, setType } from "../../utils";

const Title = styled.h3`
  ${setType("m")};
  color: ${color.black};
  font-weight: bold;
`;

Title.propTypes = {};

Title.defaultProps = {};

export default Title;
