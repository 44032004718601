import React from "react";
import styled from "styled-components";

import { breakpoint } from "../../utils";
import { logo } from "../../assets";

const LogoEl = styled.img`
  display: inline-block;
  height: 90px;
  ${breakpoint.onlyphone} {
    height: 73px;
  }
`;

const Logo = () => <LogoEl src={logo} alt="Agata Milik — Psychoterapeuta" />;

export default Logo;
