import { bool } from "prop-types";
import React from "react";
import styled from "styled-components";

import { color, setSpace, time } from "../../utils";
import withObfuscation from "./withObfuscation";

const ButtonEl = styled.button`
  ${setSpace("pan")};
  background: none;
  border: none;
  box-shadow: none;
  color: ${color.monoBlk};
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-size: inherit;
  font-weight: 500;
  outline: none;
  text-decoration: underline;
  transition: color ${time.m};
  &:hover {
    color: ${color.black};
  }
`;

const ObfButton = withObfuscation(ButtonEl);

const Button = (props) =>
  props.obfuscated ? <ObfButton {...props} /> : <ButtonEl {...props} />;

Button.propTypes = {
  obfuscated: bool
};

Button.defaultProps = {
  obfuscated: false
};

export default Button;
