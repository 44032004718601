import React from "react";
import Helmet from "react-helmet";

import meta from "../content/meta";
import { portrait1x } from "../assets";

const Init = () => (
  <Helmet
    encodeSpecialCharacters
    meta={[
      { name: "charset", content: "utf-8" },
      { name: "description", content: meta.description },
      { name: "keywords", content: meta.keywords },
      { name: "title", content: meta.title },
      { name: "viewport", content: meta.viewport }
    ]}
    defaultTitle={meta.title}
  >
    <html lang="pl" />

    {/* meta */}
    <meta name="description" content={meta.description} />
    <meta
      name="google-site-verification"
      content="1trsiEfRZF_AjUbTB0oCBDMJzfDQhBtZp8vAU2Y7SYg"
    />

    {/* Twitter Card */}
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:creator" content="@moglistudio" />

    {/* Facebook OG */}
    <meta property="og:description" content={meta.description} />
    <meta property="og:image" content={portrait1x} />
    <meta property="og:locale" content="pl" />
    <meta property="og:site_name" content={meta.title} />
    <meta property="og:title" content={meta.title} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://agatamilik.pl" />

    <link rel="icon" type="image/x-icon" href='/favicon.ico' />
    <link rel="canonical" href="https://agatamilik.pl" />
  </Helmet>
);

export default Init;
