import styled from "styled-components";

import { breakpoint, setSpace } from "../../utils";

const Actionbar = styled.div`
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  text-align: center;
  width: 100%;
  ${breakpoint.tablet} {
    flex-direction: row;
  }
  & > * {
    ${setSpace("mvs")};
    clear: both;
    display: inline-block;
    ${breakpoint.tablet} {
      ${setSpace("mhs")};
      clear: none;
      display: inline-block;
    }
  }
`;

export default Actionbar;
