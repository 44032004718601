export const radii = {
  a: "100px",
  h: "20px",
  l: "10px",
  m: "6px",
  s: "4px",
  x: "2px",
  n: "0"
};

export const radius = radii;
