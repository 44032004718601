import styled from "styled-components";
import React from "react";

import { Button, Copy, Link, Logo } from "../components";
import { breakpoint, color, setSpace, setType, time } from "../utils";

const SidebarEl = styled.div`
  text-align: center;
  ${setSpace("pam")};
  transition: background ${time.l}, box-shadow ${time.l}, opacity ${time.s},
    transform ${time.l};
  ${breakpoint.onlyphone} {
    box-shadow: 0 -1px 3px ${color.shadowHL};
    background: ${color.white};
    left: 0;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 15;
    ${({ offset }) =>
      offset
        ? `
        opacity: 1;
      `
        : `
      `};
  }
  ${breakpoint.tablet} {
    ${setSpace("pal")};
    align-content: center;
    align-items: flex-start;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    right: 0;
    position: fixed;
    top: 0;
    width: 220px;
  }
`;

const SidebarHd = styled.header`
  text-align: center;
  width: 100%;
  ${breakpoint.onlyphone} {
    display: none;
  }
`;

const SidebarBd = styled.nav`
  width: 100%;
  ${breakpoint.onlyphone} {
    text-align: center;
    & > * {
      ${setSpace("phm")};
      ${setSpace("man")};
    }
  }
  ${breakpoint.tablet} {
    & > * {
      ${setSpace("pvm")};
      ${setSpace("man")};
      display: block;
    }
  }
`;

const SidebarFt = styled.aside`
  ${setType("x")};
  width: 100%;
  ${breakpoint.onlyphone} {
    display: none;
  }
`;

const KontaktLink = styled(Link)`
  ${breakpoint.tablet} {
    display: none;
  }
`;

export default class Sidebar extends React.Component {
  constructor() {
    super();
    this.state = { hasOffset: false };
    this.updateOffset = this.updateOffset.bind(this);
  }
  componentDidMount() {
    this.updateOffset();
    window.addEventListener("scroll", this.updateOffset);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.updateOffset);
  }
  updateOffset() {
    return this.setState({ hasOffset: window.pageYOffset > 0 });
  }
  render() {
    return (
      <SidebarEl offset={this.state.hasOffset.toString()}>
        <SidebarHd>
          <Link href="#">
            <Logo />
          </Link>
        </SidebarHd>
        <SidebarBd>
          <Link href="#">Home</Link>
          <Link href="#o-mnie">O mnie</Link>
          <Link href="#oferta">Oferta</Link>
          <KontaktLink href="#kontakt">Kontakt</KontaktLink>
        </SidebarBd>
        <SidebarFt>
          <Copy>
            <p>
              Agata Milik-Brzezińska
              <br />
              ul. Wilcza 1/37 Gdańsk
              <br />
              +48 506 171 274
              <br />
              <Button
                email="aga.milik@gmail.com"
                headers={{
                  subject: "Zapytanie ze strony agatamilik.pl",
                  body: "Dzień dobry Pani Agato,",
                }}
                obfuscated
              />
            </p>
          </Copy>
        </SidebarFt>
      </SidebarEl>
    );
  }
}

Sidebar.propTypes = {};

Sidebar.defaultProps = {};
