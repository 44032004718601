import {} from "prop-types";
import styled from "styled-components";

import { color, setType } from "../../utils";

const PageTitle = styled.h1`
  ${setType("h")};
  color: ${color.black};
  font-weight: bold;
  text-transform: uppercase;
`;

PageTitle.propTypes = {};

PageTitle.defaultProps = {};

export default PageTitle;
