const meta = {
  title: "Agata Milik-Brzezińska — Psycholog i Psychoterapeuta, Gdańsk",
  description:
    "Terapia indywidualna, par i rodzin oraz wsparcie w trudnych momentach życiowych na terenie Gdańska i Trójmiasta",
  keywords:
    "terapia, indywidualna, par, psycholog, terapeuta, rodzinna, wsparcie, psychoterapeuta, gdańsk, gdynia, sopot, trójmiasto",
  viewport:
    "width=device-width, minimum-scale = 1.0, initial-scale = 1.0, maximum-scale = 5.0, user-scalable=yes, shrink-to-fit=no",
};

export default meta;
