export const spaces = {
  h: ["48px", "80px"],
  l: ["24px", "40px"],
  m: ["12px", "20px"],
  s: ["6px", "10px"],
  x: ["3px", "5px"],
  n: ["0px", "0px"]
};

export const space = spaces;
