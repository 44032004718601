import { func } from "prop-types";
import Modal from "react-modal";
import React, { Component } from "react";
import styled from "styled-components";

import {
  Actionbar,
  Container,
  Copy,
  ModalDialog,
  Section,
  SectionBd,
  SectionHd,
  Separator,
  PageSubtitle,
} from "../components";
import { breakpoint, color, setSpace } from "../utils";

const AboutEl = styled(Container)`
  ${setSpace("pvm")};
  ${setSpace("phl")};
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  min-height: 100vh;
  position: relative;
  ${breakpoint.tablet} {
    ${setSpace("pll")};
    ${setSpace("prn")};
    ${setSpace("pvl")};
  }
`;

const ToggleButton = styled.a`
  ${setSpace("phm")};
  ${setSpace("pvs")};
  border: 1px solid ${color.black};
  color: ${color.black};
  cursor: pointer;
  display: inline-block;
  min-width: 200px;
  text-align: center;
`;

const EducationModal = styled.div``;
const CoursesModal = styled.div``;

export default class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coursesModal: false,
      educationModal: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(modal) {
    this.setState({ [modal]: true });
    this.props.toggleBodyCrop(true);
  }

  closeModal(modal) {
    this.setState({ [modal]: false });
    this.props.toggleBodyCrop(false);
  }

  render() {
    const { educationModal, coursesModal } = this.state;
    return [
      <AboutEl {...this.props} key="about">
        <Section
          backg="radial-gradient(ellipse at top, white 0, white 15%, #e9e9e7 100%);"
          cover
        >
          <SectionHd>
            <PageSubtitle>O mnie</PageSubtitle>
          </SectionHd>
          <Separator silent size="m" />
          <SectionBd limit="s">
            <Copy>
              <p className="spark">
                Jestem psychoterapeutką i psycholożką ze stopniem doktora nauk społecznych. Ukończyłam pięcioletnie studia magisterskie z psychologii, proces doktoryzacji na Uniwersytecie Gdańskim, czteroletni Całościowy Kurs Psychoterapii w Ośrodku Szkoleń Systemowych w Krakowie certyfikowany przez Polskie Towarzystwo Psychologiczne, dwuletnią Zaawansowaną Szkołę Współczesnej Psychoterapii Psychodynamicznej przy Ośrodku Humani w Poznaniu i Laboratorium Psychoedukacji w Warszawie, dwuletnią Szkołę Psychoterapii Grupowej w Laboratorium Psychoedukacji w Warszawie oraz dwuletnią Szkołę Psychoterapii Osób z Problemem Uzależnienia w Laboratorium Psychoedukacji w Warszawie. Aktualnie jestem uczestniczką Akademii Mentalizacji w Ośrodku Humani w Poznaniu.
              </p>
              <p>
                Swoje doświadczenie zdobywałam podczas pracy w Centrum Interwencji Kryzysowej FDDS w Gdańsku, na Oddziale Dziennym Zaburzeń Nerwicowych w SPZOZ w Starogardzie Gdańskim i w Pomorskim Centrum Psychiatrii w Gdańsku, a aktualnie wzbogacam je w Centrum Pomocy Dzieciom FDDS w Gdańsku oraz przy licznych projektach psychologicznych i psychoterapeutycznych we współpracy ze szpitalami, szkołami, poradniami i innymi instytucjami. Jestem członkinią zespołu Gdyńskiego Centrum Psychoterapii Grupowej, w którym współprowadzę grupy psychoterapeutyczne.
              </p>
              <p>
                Prowadzę szkolenia i warsztaty z zakresu wybranej tematyki psychologicznej i psychoterapeutycznej. Regularnie uczestniczę w seminariach, konferencjach i szkoleniach w kraju i za granicą, które poszerzają obszary mojej wiedzy i kompetencji. Swoją pracę poddaję stałej superwizji indywidualnej i grupowej u certyfikowanych superwizorów w Polsce i za granicą.
              </p>
            </Copy>
            <Separator silent size="m" />
            <Actionbar>
              <ToggleButton onClick={() => this.openModal("educationModal")}>
                Wykształcenie
              </ToggleButton>
              <ToggleButton onClick={() => this.openModal("coursesModal")}>
                Przebyte Kursy
              </ToggleButton>
            </Actionbar>
          </SectionBd>
        </Section>
      </AboutEl>,
      <EducationModal key="educationModal">
        <Modal
          ariaHideApp={false}
          isOpen={educationModal}
          onRequestClose={() => this.closeModal("educationModal")}
        >
          <ModalDialog closeModal={() => this.closeModal("educationModal")}>
            <Copy>
              <h1 style={{ textAlign: "center" }}>Wykształcenie</h1>
              <Separator silent />
              <ul>
                <li>Akademia Mentalizacji – Ośrodek Humani w Poznaniu</li>
                <li>Szkoła Psychoterapii Osób z Problemem Uzależnienia – Laboratorium Psychoedukacji w Warszawie</li>
                <li>Szkoła Psychoterapii Grupowej — Laboratorium Psychoedukacji w Warszawie</li>
                <li>Zaawansowana Szkoła Współczesnej Psychoterapii Psychodynamicznej — Laboratorium Psychoedukacji w Warszawie i Pracownia Humani w Poznaniu.</li>
                <li>Ośrodek Szkoleń Systemowych w Krakowie – Czteroletni Całościowy Kurs Psychoterapii (Certyfikowany przez Polskie Towarzystwo Psychologiczne)</li>
                <li>Polski Instytut Ericksonowski — Roczny kurs „Podróż na Squaw Peak – psychoterapia małżeństw i par”</li>
                <li>Gdański Uniwersytet Medyczny — Roczne Studia Podyplomowe – Psychologia Kliniczna</li>
                <li>Uniwersytet Gdański — Wydział Nauk Społecznych — Stopień naukowy doktora w dziedzinie nauk społecznych, kierunek psychologia</li>
                <li>Uniwersytet Gdański — Wydział Nauk Społecznych — Psychologia pięcioletnie studia dzienne magisterskie. Ukończone dwie ścieżki naukowe — Psychologia Kliniczna i Psychologia Dzieci i Młodzieży. W tym zrealizowany program Przygotowania Pedagogicznego</li>
              </ul>
            </Copy>
            <Separator silent size="m" />
            <div style={{ textAlign: "center" }}>
              <ToggleButton onClick={() => this.closeModal("educationModal")}>
                Powrót
              </ToggleButton>
            </div>
          </ModalDialog>
        </Modal>
      </EducationModal>,
      <CoursesModal key="coursesModal">
        <Modal
          ariaHideApp={false}
          isOpen={coursesModal}
          onRequestClose={() => this.closeModal("coursesModal")}
        >
          <ModalDialog closeModal={() => this.closeModal("coursesModal")}>
            <Copy>
              <h1 style={{ textAlign: "center" }}>Przebyte Kursy</h1>
              <Separator silent />
              <ul>
                <li>Psychoterapia oparta na Mentalizacji MBT I i II stopień</li>
                <li>Program „Lighthouse Family” w oparciu o MBT</li>
                <li>Terapia Par Skoncentrowana na Emocjach (EFT)</li>
                <li>Wprowadzenie do teorii i praktyki ISTDP</li>
                <li>Terapia Skoncentrowana na Rozwiązaniu – I i II stopień</li>
                <li>Praca z klientem po traumie w TSR</li>
                <li>Interwencja Kryzysowa</li>
                <li>Metody i strategie pomocy psychologicznej dla par</li>
                <li>
                  Zmiana sposobu myślenia, czyli skuteczne zastosowanie technik
                  poznawczo-behawioralnych
                </li>
                <li>Uzależnienia behawioralne</li>
                <li>
                  Wprowadzenie do psychoterapii Gestalt, ciało i sen w procesie
                  terapii
                </li>
                <li>
                  Podstawy psychoterapii Gestalt, rozszerzenie świadomości
                  własnego Ja
                </li>
                <li>
                  Formy pomocy terapeutycznej wobec pacjentów doświadczających
                  różnych postaci lęków
                </li>
                <li>Kiedy lęk z przeciwnika staje się sprzymierzeńcem</li>
                <li>
                  Diagnoza kliniczna pacjentów z zaburzeniami osobowości z
                  perspektywy różnych dysfunkcji psychopatologicznych
                </li>
                <li>
                  Tajemnice okaleczonego soma i psyche: zachowania autoagresywne
                  - kompleksowy model pomocy
                </li>
                <li>
                  Zaburzenia odżywiania – anoreksja, bulimia, nadwaga, brak
                  łaknienia – kto choruje – specyfika terapii rodzin
                </li>
                <li>
                  Najnowsze trendy w terapii poznawczej zaburzenia osobowości z
                  pogranicza. Terapia schematu i jej zastosowania
                </li>
                <li>Leczenie zaburzeń dysocjacyjnych</li>
                <li>
                  Krótkoterminowe psychodynamiczne interwencje terapeutyczne
                </li>
                <li>Mediacje – szkolenie podstawowe</li>
                <li>Trauma Focus – CBT</li>
                <li>
                  Special methods for resource-oriented trauma therapy for
                  children and adolescents
                </li>
                <li>
                  Wykorzystanie seksualne dziecka: Rozpoznanie, wsparcie,
                  zgłaszanie
                </li>
                <li>Kreatywne techniki pracy warsztatowej z grupą</li>
                <li>Projekt Drama. Drama w pracy z dzieckiem</li>
                <li>Bez klapsa – warsztat dla rodziców</li>
                <li>Stosowanie i interpretacja MMPI-2</li>
                <li>
                  Kliniczne zastosowanie testów psychometrycznych w diagnozie
                  dziecka
                </li>
                <li>
                  Zastosowanie Bayley Scales of infant and toodler development
                </li>
                <li>Terapia Behawioralna dzieci i młodzieży z autyzmem</li>
                <li>Szkolenie z metody EEG-Biofeedback I stopnia</li>
                <li>Pakiet szkoleń neuropsychologicznych</li>
              </ul>
            </Copy>
            <Separator silent size="m" />
            <div style={{ textAlign: "center" }}>
              <ToggleButton onClick={() => this.closeModal("coursesModal")}>
                Powrót
              </ToggleButton>
            </div>
          </ModalDialog>
        </Modal>
      </CoursesModal>,
    ];
  }
}

About.propTypes = {
  toggleBodyCrop: func.isRequired,
};
