import React, { Component } from "react";
import styled from "styled-components";

import {
  Button,
  Container,
  Copy,
  Icon,
  Section,
  SectionBd,
  SectionHd,
  Separator,
  Title,
  PageSubtitle,
} from "../components";
import { breakpoint, color, setSpace, time } from "../utils";

const OfferEl = styled(Container)`
  ${setSpace("pvm")};
  ${setSpace("phl")};
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  min-height: 100vh;
  position: relative;
  ${breakpoint.tablet} {
    ${setSpace("pll")};
    ${setSpace("prn")};
    ${setSpace("pvl")};
  }
`;

const OfferBd = styled.div`
  color: ${color.greyBlk};
  & > p {
    ${setSpace("mts")};
  }
`;
const OfferHd = styled.div`
  ${setSpace("pll")};
  ${setSpace("pvm")};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &:hover {
    ${Title} {
      text-decoration: underline;
      font-weight: bold;
    }
  }
  i {
    left: 0;
    position: absolute;
    transform: translateY(3px);
  }
`;
const OfferPrice = styled.span`
  color: ${color.monoM};
  font-weight: 500;
  text-align: right;
  flex: 1 0 140px;
`;
const OfferItem = styled.div`
  ${OfferBd} {
    ${setSpace("pll")};
    backface-visibility: hidden;
    height: ${({ isActive }) => (isActive ? `auto` : `0px`)};
    overflow: hidden;
    transform: translate(0, 0);
    transition: all ${time.m};
  }
`;

export default class Offer extends Component {
  constructor(props) {
    super(props);
    this.state = { current: "terapia-indywidualna" };
    this.toggleOffer = this.toggleOffer.bind(this);
  }

  toggleOffer(target, e) {
    const { current } = this.state;
    const ref = e.currentTarget;
    this.setState({ current: current === target ? null : target }, () => {
      if (current === null) {
        setTimeout(
          ref.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          }),
          1500,
        );
      }
    });
  }

  render() {
    const { current } = this.state;
    return (
      <OfferEl {...this.props}>
        <Section
          backg="radial-gradient(ellipse at top, white 0, white 15%, #e9e9e7 100%);"
          cover
        >
          <SectionHd>
            <PageSubtitle>Oferta</PageSubtitle>
          </SectionHd>
          <Separator silent size="m" />
          <SectionBd limit="s">
            <OfferItem isActive={current === "terapia-indywidualna"}>
              <OfferHd
                onClick={(e) => this.toggleOffer("terapia-indywidualna", e)}
              >
                <Icon
                  name={
                    current === "terapia-indywidualna"
                      ? `arr-down`
                      : `arr-right`
                  }
                  size="x"
                />
                <Title>Psychoterapia Indywidualna</Title>
                <OfferPrice>200 zł / 50 minut</OfferPrice>
              </OfferHd>
              <OfferBd>
                <Copy>
                  <p>
                    Psychoterapia jest formą leczenia, pomagającą poszerzyć
                    świadomość i zbudować dobre relacje z samym sobą i osobami
                    wokół, rozwija możliwe ścieżki działań i podejmowania
                    decyzji. Opiera się na przyglądaniu się światu wewnętrznemu,
                    swoim uczuciom, myślom i mechanizmom zachowania. Sięga się
                    do wspomnień, przeszłości, relacji z innymi po to, by lepiej
                    móc zrozumieć siebie i swoje trudności. Pierwsze spotkania
                    służą terapeucie w rozpoznaniu sytuacji zgłaszającej się
                    osoby i dają możliwość wyboru pomocy, która będzie
                    dostosowana do potrzeb i możliwości pacjenta. Spotkania
                    indywidualne prowadzone są w oparciu o nurty
                    psychoterapeutyczne: systemowy i psychodynamiczny ze
                    szczególnym uwzględnieniem Terapii Opartej na Mentalizacji.
                  </p>
                </Copy>
              </OfferBd>
            </OfferItem>
            <Separator size="m" />
            <OfferItem isActive={current === "wsparcie-kryzysowe"}>
              <OfferHd
                onClick={(e) => this.toggleOffer("wsparcie-kryzysowe", e)}
              >
                <Icon
                  name={
                    current === "wsparcie-kryzysowe" ? `arr-down` : `arr-right`
                  }
                  size="x"
                />
                <Title>Wsparcie w trudnych momentach życiowych</Title>
                <OfferPrice>200 zł / 50 minut</OfferPrice>
              </OfferHd>
              <OfferBd>
                <Copy>
                  <p>
                    Krótkoterminowa forma wsparcia psychologicznego w
                    kryzysowych momentach życiowych. Spotkania indywidualne
                    prowadzone w oparciu o teorię interwencji kryzysowej i
                    rozumienie psychodynamiczne oraz systemowe.
                  </p>
                </Copy>
              </OfferBd>
            </OfferItem>
            <Separator size="m" />
            <OfferItem isActive={current === "psychoterapia-grupowa"}>
              <OfferHd
                onClick={(e) => this.toggleOffer("psychoterapia-grupowa", e)}
              >
                <Icon
                  name={
                    current === "psychoterapia-grupowa"
                      ? `arr-down`
                      : `arr-right`
                  }
                  size="x"
                />
                <Title>Psychoterapia grupowa</Title>
                <OfferPrice>
                  <Button
                    email="aga.milik@gmail.com"
                    headers={{
                      subject: "Zapytanie ze strony agatamilik.pl",
                      body: "Dzień dobry Pani Agato,",
                    }}
                    text="Napisz do mnie"
                    obfuscated
                  />
                </OfferPrice>
              </OfferHd>
              <OfferBd>
                <Copy>
                  <p>
                    Psychoterapia grupowa jest formą uczenia się przez
                    doświadczenie, któremu towarzyszą refleksje i informacje
                    zwrotne od pozostałych uczestników i terapeutów. Ten rodzaj
                    pracy daje możliwość rozpoznania nieświadomych konfliktów i
                    patogennych założeń, które leżą u podstaw nieadaptacyjnych
                    wzorców relacyjnych. Umożliwia poszerzenie i pogłębienie
                    świadomości, zwiększenie odpowiedzialności za swoje
                    zachowania i podkreśla możliwość dokonywania wyborów. Grupa
                    staje się środowiskiem terapeutycznym, które sprzyja uczeniu
                    się przez doświadczenie, poznawaniu siebie, innych ludzi i
                    związków z nimi. Psychoterapia grupowa prowadzona jest przez
                    dwóch psychoterapeutów w zintegrowanym podejściu
                    interpersonalnym, egzystencjalnym i psychodynamicznym.
                  </p>
                  <p>
                    Zapraszam również na stronę{" "}
                    <a
                      href="https://www.gcpg.pl/"
                      style={{ color: "black" }}
                      target="_blank"
                      rel="noreferer"
                    >
                      Gdyńskiego Centrum Psychoterapii Grupowej
                    </a>
                    , którego jestem częścią.
                  </p>
                </Copy>
              </OfferBd>
            </OfferItem>
            <Separator size="m" />
            <OfferItem isActive={current === "warsztaty"}>
              <OfferHd onClick={(e) => this.toggleOffer("warsztaty", e)}>
                <Icon
                  name={current === "warsztaty" ? `arr-down` : `arr-right`}
                  size="x"
                />
                <Title>Warsztaty i szkolenia</Title>
                <OfferPrice>
                  <Button
                    email="aga.milik@gmail.com"
                    headers={{
                      subject: "Zapytanie ze strony agatamilik.pl",
                      body: "Dzień dobry Pani Agato,",
                    }}
                    text="Napisz do mnie"
                    obfuscated
                  />
                </OfferPrice>
              </OfferHd>
              <OfferBd>
                <Copy>
                  <p>
                    Prowadzę warsztaty i szkolenia o wybranej tematyce
                    psychologicznej i psychoterapeutycznej. Do tej pory
                    odbiorcami tych działań, były różne grupy zawodowe, małe
                    firmy, wolontariusze, personel medyczny, nauczyciele,
                    rodzice, studenci, nastolatkowie, dzieci, osoby
                    doświadczające kryzysów psychologicznych, osoby bezrobotne,
                    osoby chorujące na choroby przewlekłe.
                  </p>
                  <p>
                    Istnieje możliwość przeprowadzenia szkoleń specjalistycznych
                    z wybranej tematyki psychologicznej lub
                    psychoterapeutycznej, a także warsztatów rozwojowych dla
                    wybranych grup odbiorców. W celu uzyskania większej ilości
                    informacji proszę o kontakt telefoniczny bądź wiadomość
                    mailową.
                  </p>
                </Copy>
              </OfferBd>
            </OfferItem>
          </SectionBd>
        </Section>
      </OfferEl>
    );
  }
}
