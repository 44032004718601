export const leading = {
  h: "1.8em",
  l: "1.6em",
  m: "1.4em",
  s: "1.2em",
  x: "1",
  n: "0"
};

export const lead = leading;
