import css from "styled-components";

import { color, setSpace, setType } from "../../utils";

const Copy = css.div`
  color: ${color.monoM};
  h2:not(:first-child),
  h3:not(:first-child),
  p:not(:first-child),
  ol:not(:first-child),
  ul:not(:first-child) {
    ${setSpace("mtm")};
  }
  h1, h2, h3 {
    color: ${color.black};
    font-weight: bold;
  }
  h1 {
    ${setType("l")};
  }
  h2 {
    ${setType("m")};
  }
  h3 {
    ${setType("s")};
  }
  p {

  }
  p.spark {
    ${setType("m")};
    color: ${color.monoBlk};
  }
  strong {
    font-weight: 500;
  }
  hr { }
  ul { }
  ul li { list-style: disc inside; }
  ol { }
  ol li { list-style: disc inside; }
`;

export default Copy;
