import { func } from "prop-types";
import React from "react";
import styled from "styled-components";

import { breakpoint, color, setSpace } from "../../utils";
import "./modal.css";

const ModalDialogEl = styled.div`
  ${setSpace("pal")};
  background: ${color.white};
  border: 1px solid ${color.greyHL};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
  & > * {
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
  }
  ${breakpoint.tablet} {
    ${setSpace("pah")};
  }
`;

const ModalBody = styled.div`
  overflow-y: scroll;
  overflow-x: visible;
`;

const ModalClose = styled.a`
  ${setSpace("pan")};
  background: ${color.white};
  color: ${color.black};
  cursor: pointer;
  display: block;
  font-size: 48px;
  height: 40px;
  line-height: 40px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  transform: translate(50%, -50%);
  width: 40px;
`;

const ModalDialog = (props) => (
  <ModalDialogEl {...props}>
    <ModalClose onClick={props.closeModal}>×</ModalClose>
    <ModalBody>{props.children}</ModalBody>
  </ModalDialogEl>
);

ModalDialog.propTypes = {
  closeModal: func
};

export default ModalDialog;
