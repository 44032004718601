import React from "react";
import styled from "styled-components";

import {
  Button,
  Container,
  Copy,
  Section,
  SectionBd,
  SectionHd,
  Separator,
  PageSubtitle,
} from "../components";
import { breakpoint, setSpace } from "../utils";

const ContactEl = styled(Container)`
  ${setSpace("phl")};
  ${setSpace("pvh")};
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  min-height: 100vh;
  position: relative;
  text-align: center;
  ${breakpoint.tablet} {
    display: none;
  }
  ${Section} {
    justify-content: center;
  }
`;

const Contact = (props) => (
  <ContactEl {...props}>
    <Section cover>
      <SectionHd>
        <PageSubtitle>Kontakt</PageSubtitle>
      </SectionHd>
      <Separator silent size="m" />
      <SectionBd limit="s">
        <Copy>
          <p>Agata Milik-Brzezińska</p>
          <p>ul. Wilcza 1/37, Gdańsk</p>
          <p>+48 506 171 274</p>
          <p>
            <Button
              email="aga.milik@gmail.com"
              headers={{
                subject: "Zapytanie ze strony agatamilik.pl",
                body: "Dzień dobry Pani Agato,",
              }}
              obfuscated
            />
          </p>
        </Copy>
      </SectionBd>
    </Section>
  </ContactEl>
);

export default Contact;
