/* eslint no-return-assign: 0 */
import React, { Component } from "react";
import styled from "styled-components";

import { Fold, About, Offer, Contact } from "../sections";
import { Init, Sidebar } from "../partials";
import { Container } from "../components";
import { breakpoint } from "../utils";

const Content = styled(Container)`
  ${breakpoint.tablet} {
    margin-right: 220px;
  }
`;

export default class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = { bodyCrop: false };
    this.onBodyCrop = this.onBodyCrop.bind(this);
    this.toggleBodyCrop = this.toggleBodyCrop.bind(this);
  }
  onBodyCrop() {
    const { body } = document;
    const { bodyCrop } = this.state;
    body.style.overflow = bodyCrop ? `hidden` : `visible`;
    body.style.height = bodyCrop ? `${window.innerHeight}px` : `initial`;
  }
  toggleBodyCrop(mode) {
    this.setState({ bodyCrop: mode }, () => this.onBodyCrop());
  }
  render() {
    return [
      <Init key="init" />,
      <Sidebar key="sidebar" />,
      <Content key="content">
        <Fold />
        <About id="o-mnie" toggleBodyCrop={this.toggleBodyCrop} />
        <Offer id="oferta" />
        <Contact id="kontakt" />
      </Content>,
    ];
  }
}
