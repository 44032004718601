import styled from "styled-components";

import { breakpoint, setSpace } from "../../utils";

const Section = styled.section`
  ${setSpace("pal")};
  background-image: ${({ backg }) => backg || `none`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: ${({ cover }) => (cover ? `100%` : `none`)};
  position: relative;
  width: 100%;
  ${breakpoint.tablet} {
    ${setSpace("pah")};
  }
`;

export default Section;
