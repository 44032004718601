import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

import {
  Button,
  Container,
  Copy,
  Logo,
  Section,
  SectionBd,
  SectionHd,
  Separator,
  PageTitle,
} from "../components";
import { portrait2x } from "../assets";
import { breakpoint, color, setSpace, setType } from "../utils";

const FoldEl = styled(Container)`
  ${setSpace("pbl")};
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  position: relative;
  ${breakpoint.tablet} {
    min-height: 100vh;
    ${setSpace("pll")};
    ${setSpace("prn")};
    ${setSpace("pvl")};
    &:before {
      background: radial-gradient(
        ellipse at 20% 90%,
        ${color.flareBlk} 0,
        ${color.flareBlk} 25%,
        transparent 50%,
        transparent 100%
      );
      bottom: 0;
      content: "";
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 10;
    }
  }
  ${breakpoint.desktop} {
    &:before {
      background: radial-gradient(
        ellipse at 66% 90%,
        ${color.flareBlk} 0,
        ${color.flareBlk} 25%,
        transparent 50%,
        transparent 100%
      );
    }
  }
`;

const FoldSection = styled(Section)`
  ${setSpace("pan")};
  ${breakpoint.tablet} {
    background-image: url(${portrait2x});
  }
`;

const FoldHd = styled(SectionHd)`
  ${setSpace("pan")};
  ${setSpace("mbl")};
  ${breakpoint.tablet} {
    display: none;
  }
`;

const FoldLogoHolder = styled.span`
  ${setSpace("pam")};
  background: white;
  bottom: 5%;
  display: inline-block;
  left: 50%;
  line-height: 0;
  position: absolute;
  transform: translate(-50%, 50%);
`;

const FoldBd = styled(SectionBd)`
  text-align: center;
  ${breakpoint.onlyphone} {
    ${setSpace("pal")};
  }
  ${breakpoint.tablet} {
    bottom: 10%;
    max-width: 80%;
    position: absolute;
    right: 10%;
    text-align: left;
    z-index: 15;
  }
  ${breakpoint.desktop} {
    max-width: 600px;
  }
`;

const Pitch = styled(PageTitle)`
  ${setType("m")};
  ${breakpoint.tablet} {
    ${setType("l")};
  }
`;

const Fold = (props) => (
  <FoldEl {...props}>
    <FoldSection cover>
      <FoldHd>
        <StaticImage
          src="../assets/portrait2x.jpg"
          alt="Agata Milik-Brzezińska, portret"
          layout="fullWidth"
        />
        <FoldLogoHolder>
          <Logo />
        </FoldLogoHolder>
      </FoldHd>
      <FoldBd>
        <Pitch>
          Towarzyszę na drodze do poszerzenia świadomości i rozumienia siebie,
          swoich emocji, myśli i schematów zachowań.
        </Pitch>
        <Separator silent size="s" />
        <Copy>
          <p className="spark">
            Pomagam budować i pogłębiać relacje z samym sobą, innymi ludźmi i
            światem. Wspieram w trudnych momentach życiowych i kryzysach.
          </p>
          <p className="spark">
            <Button
              email="aga.milik@gmail.com"
              headers={{
                subject: "Zapytanie ze strony agatamilik.pl",
                body: "Dzień dobry Pani Agato,",
              }}
              obfuscated
              text="Napisz do mnie"
            />{" "}
            lub skontaktuj się telefonicznie: <strong>506 171 274</strong>.
          </p>
        </Copy>
      </FoldBd>
    </FoldSection>
  </FoldEl>
);

export default Fold;
